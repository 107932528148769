<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-4 my-auto">
        <h6 class="fw-bold text-primary mb-0">Tile Background Color</h6>
      </div>
      <div class="col-md-4 my-auto">
        <tailwind-color-selector
          v-model="options.tileBgColor"
        ></tailwind-color-selector>
      </div>
    </div>


    <!--  -->

    <div class="row mb-4">
      <div class="col-md-4 my-auto">
        <h6 class="fw-bold text-primary mb-0">Tile Text Color</h6>
      </div>
      <div class="col-md-4 my-auto">
        <tailwind-color-selector
          v-model="options.tileTextColor"
        ></tailwind-color-selector>
      </div>
    </div>
  </div>
</template>

<script>
import TailwindColorSelector from "@/views/components/colors/TailwindColorSelector";

export default {
  props: {
    options: {
      type: Object,
    },
  },
  components: {
    TailwindColorSelector,
  },
};
</script>

<style>
</style>