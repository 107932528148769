<template>
  <div>
    <h3 class="fw-bold text-primary">App Features</h3>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-11">
            <div class="row mb-4" v-if="app">
              <div class="col-md-3 mb-3" v-for="f in availableFeatures" :key="'feature' + f.id">
                <div class="card border-0 shadow-sm cursor-pointer" @click="toggleFeature(f)">
                  <div class="card-body text-center text-primary">
                    <div
                      v-if="hasFeatureEnabled(f)"
                      class="badge bg-primary text-white"
                      style="position: absolute; top: 0; right: 0"
                    >
                      <small>Enabled</small>
                    </div>
                    <div class="mb-2">
                      <i class="far fa-2x" :class="f.icon"></i>
                    </div>
                    <h5 class="fw-bold">{{ f.title }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div
          class="row mb-4"
          v-for="f in app.features"
          :key="'feature-options' + f.id"
          v-if="hasFeatureEnabled(f)"
        >
          <div class="col-md-11">
            <div class="card border-0 shadow-none bg-light">
              <div class="card-body">
                <h4 class="text-primary fw-bold mb-4">{{ f.title }} - Options</h4>
                <order-options v-if="f.id === 1" :options="f.options"></order-options>

                <appointment-options v-if="f.id === 2" :options="f.options"></appointment-options>

                <consultation-options v-if="f.id === 3" :options="f.options"></consultation-options>

                <resource-options v-if="f.id === 4" :options="f.options"></resource-options>

                <health-tracker-options
                  v-if="f.id === 5"
                  :options="f.options"
                ></health-tracker-options>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
      <div class="col-auto ms-auto pe-5">
        <phone-simulator :src="pwaUrl"></phone-simulator>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/services/firebase";
const db = firebase.firestore();
import PhoneSimulator from "../partials/PhoneSimulator.vue";
import TailwindColorSelector from "@/views/components/colors/TailwindColorSelector";
import OrderOptions from "./feature-options/OrderOptions.vue";
import AppointmentOptions from "./feature-options/AppointmentOptions.vue";
import ResourceOptions from "./feature-options/ResourceOptions.vue";
import HealthTrackerOptions from "./feature-options/HealthTrackerOptions.vue";
import ConsultationOptions from "./feature-options/ConsultationOptions.vue";

export default {
  data() {
    return {
      app: null,
      config: null,
      availableFeatures: [
        {
          id: 1,
          title: "Orders",
          icon: "fa-shopping-cart",
          to: "/orders",
          options: {
            reOrderEnabled: false,
          },
        },
        {
          id: 2,
          title: "Appointments",
          icon: "fa-calendar-star",
          to: "/appointments",
          options: {},
        },
        // {
        //   id: 3,
        //   title: "Consultations",
        //   icon: "fa-camera",
        //   to: "/consultations",
        //   options: {},
        // },
        {
          id: 4,
          title: "Resources",
          icon: "fa-paperclip",
          to: "/resources",
          options: {},
        },
        {
          id: 5,
          title: "Health Tracker",
          icon: "fa-monitor-waveform",
          to: "/health-tracker",
          options: {},
        },
        {
          id: 6,
          title: "T&Cs",
          icon: "fa-handshake",
          to: "/terms",
          options: {},
        },
        {
          id: 7,
          title: "Health Diary",
          icon: "fa-book-medical",
          to: "/health-diary",
          options: {},
        },
        {
          id: 8,
          title: "Forms",
          icon: "fa-clipboard-list",
          to: "/forms",
          options: {},
        },
      ],
    };
  },
  computed: {
    pwaUrl() {
      return "https://pwa-demo.swandoola.com/dashboard?v=" + Date.now();
      //  return "https://pwa.swandoola.jtg:8081"
    },
  },
  watch: {
    config: {
      handler: function (val) {
        this.updateConfig();
      },
      deep: true,
    },
    app: {
      handler: function (val) {
        this.updateApp();
      },
      deep: true,
    },
  },
  methods: {
    hasFeatureEnabled(feature) {
      if (this.app.features) {
        const exists = this.app.features.findIndex((x) => x.id === feature.id);
        if (exists > -1) {
          return true;
        }
      }
      return false;
    },
    toggleFeature(feature) {
      let newFeatures = [];

      let current;

      if (this.app.features) {
        current = this.app.features;
      } else {
        current = [];
      }

      // check if the feature is in current

      const existsCurrent = current.findIndex((x) => x.id === feature.id);

      if (existsCurrent > -1) {
        // exists do nothing
        current.splice(existsCurrent, 1);
      } else {
        newFeatures.push(feature);
      }

      this.app.features = [...current, ...newFeatures];
      this.updateApp();
      // const currentFeatures = this.app.features ? this.app.features : [];

      // const newFeatures = this.app.features ? this.app.features : [];

      // if (newFeatures.length === 0) {
      //   newFeatures.push(feature);
      // } else {
      //   const exists = newFeatures.findIndex((x) => x.id === feature.id);
      //   if (exists > -1) {
      //     newFeatures.splice(exists, 1);
      //   } else {
      //     newFeatures.push(feature);
      //   }
      // }

      // this.app.features = [...currentFeatures, ...newFeatures];
    },
    async readApp() {
      const doc = await db.collection("apps").doc("wXUPsAWUlid7azNBdZ33").get();

      const data = {
        id: doc.id,
        ...doc.data(),
      };
      this.app = data;
    },
    async updateApp() {
      console.log("updating app");
      await db.collection("apps").doc("wXUPsAWUlid7azNBdZ33").update(this.app);
    },

    async readConfig() {
      const doc = await db.collection("configs").doc("wXUPsAWUlid7azNBdZ33").get();

      const data = {
        id: doc.id,
        ...doc.data(),
      };
      this.config = data;
    },
    async updateConfig() {
      await db.collection("configs").doc("wXUPsAWUlid7azNBdZ33").update(this.config);
    },
  },
  created() {
    this.readApp();
    this.readConfig();
  },
  components: {
    PhoneSimulator,
    TailwindColorSelector,
    OrderOptions,
    AppointmentOptions,
    ResourceOptions,
    HealthTrackerOptions,
    ConsultationOptions,
  },
};
</script>

<style></style>
